<script setup>

    import BlockLanding from "@/Pages/LandingPage/BlockLanding.vue";
    import BlockAppairageSimple from "@/Pages/LandingPage/BlockAppairageSimple.vue";
    import BlockFemmeHomme from "@/Pages/LandingPage/BlockFemmeHomme.vue";
    import BlockHautParleur from "@/Pages/LandingPage/BlockHautParleur.vue";
    import BlockAutonomieBatterie from "@/Pages/LandingPage/BlockAutonomieBatterie.vue";
    import BlockScenneUsage from "@/Pages/LandingPage/BlockScenneUsage.vue";
    import BlockOptiques from "@/Pages/LandingPage/BlockOptiques.vue";
    import AppLayout from "@/Layouts/AppLayout.vue";
    import NavBar from "@/Components/NavBar.vue";
    import ModalCart from "@/Components/ModalCart.vue";
    import {ref} from "vue";
    import VideoHeadPageLanding from "@/Pages/ItemType/Box/VideoHeadPageLanding.vue";
    const show = ref(false);
    const props = defineProps({
        auth:Object,
        canLogin: Boolean,
        canRegister: Boolean,
        shipping_rate_country:{type:Object,required:false}
    });
    function handleToogleCart() {
        show.value = !show.value;
        if(show.value) {
            // Ajouter le tracking Matomo
            if (window._paq) {
                window._paq.push(['trackEvent', 'Panier', 'Afficher','afficher depuis page accueil']);
            }
        }
    }

</script>
<template>
    <app-layout>
        <nav-bar
            class="sticky top-0 z-50 w-full drop-shadow shadow-pink-400"
            @emitToogleCart="handleToogleCart"
            :auth="auth"
        />
        <main class="w-full overflow-hidden text-gray-700 pb-4">
            <section class="relative">
                <h1 class="absolute top-5 right-3 z-10 text-elionPurple-800 lg:pr-28 text-3xl sm:text-4xl md:text-6xl lg:text-7xl font-wildyouth">
                    {{$t('landingPage.sectionLanding.title')}}
                </h1>
                <video-head-page-landing
                    :media = "'/medias/landing'"
                    :alertmessage = "$t('alertMedia.webBrowserNotSupported')"
                    :textTitle1="$t('landingPage.videoHead.videoTitle1')"
                    :textTitle2="$t('landingPage.videoHead.videoTitle2')"
                    :textTitle3="$t('landingPage.videoHead.videoTitle3')"
                />
            </section>
            <section class="w-full px-4 lg:pb-14">
                <block-landing />
            </section>
            <section class=" w-ful diagonal up bg-elionPurple-800 md:p-2xl lg:p-4xl">
                <block-scenne-usage />
            </section>
            <section class="w-full">
                <block-haut-parleur />
            </section>
            <hr class="w-11/12 mx-auto" />
            <section class="w-full">
                <block-autonomie-batterie />
            </section>
            <section class="w-full diagonal up bg-purple-100">
                <block-appairage-simple />
            </section>
            <section class="w-full flex items-center -mt-24 pt-24
                       bg-gradient-to-r from-gray-950 from-20% via-gray-850 via-60% to-gray-500 lg:py-20">
                <block-optiques />
            </section>
            <section class="w-full flex items-center justify-center">
                <block-femme-homme />
            </section>
        </main>
        <modal-cart
            :max-width="'7xl'"
            :show="show"
            :auth="auth"
            :shipping_rate_country="shipping_rate_country"
            @close="handleToogleCart"
        />
    </app-layout>
</template>